import { LanguagePack } from '../model';

const elvish: LanguagePack = {
  rules: [],
  syllables: {
    male: {
      prefix: [
        'Aa',
        'Aar',
        'Ar',
        'Ae',
        'Aer',
        'Aeg',
        'An',
        'And',
        'Ana',
        'Bar',
        'Bae',
        'Baer',
        'Byr',
        'Brae',
        'Bryn',
        'Cae',
        'Cele',
        'Cys',
        'Care',
        'Carn',
        'Da',
        'Dae',
        'Dar',
        'Daer',
        'Dyr',
        'Dim',
        'Dhor',
        'El',
        'Fran',
        'Frhon',
        'Gara',
        'Gar',
        'Gae',
        'Gy',
        'Hal',
        'Han',
        'Haer',
        'Harn',
        'Hara',
        'Hyl',
        'Him',
        'Ir',
        'Lae',
        'Pele',
        'Phyl',
        'Rho',
        'Rae',
        'Ryn',
        'Saer',
        'Sar',
        'San',
        'Sim',
        'Syl',
        'Syn',
        'Thal',
        'Than',
        'Thae',
        'Thyr',
        'Vys',
        'Vaer',
        'Var',
        'Varn',
      ],
      infix: [
        'a',
        'ael',
        'an',
        'am',
        'ana',
        'amyl',
        'ad',
        'bran',
        'cae',
        'da',
        'darn',
        'ele',
        'ewyn',
        'en',
        'ed',
        'ene',
        'esth',
        'gyl',
        'hal',
        'hyn',
        'hil',
        'il',
        'in',
        'lim',
        'laen',
        'lir',
        'la',
        'lyn',
        'min',
        'myl',
        'ma',
        'mal',
        'nir',
        'nyl',
        'na',
        'nar',
        'naer',
        'no',
        'o',
        'ra',
        'rae',
        'rho',
        'ran',
        'rean',
        'ryn',
        'rim',
        'ral',
        'ril',
        'sil',
        'sin',
        'sy',
        'syng',
        'sa',
        'til',
        'thyl',
        'vyn',
        'vaer',
        'yl',
        'yr',
        'yn',
      ],
      suffix: [
        'an',
        'ar',
        'dar',
        'dan',
        'daer',
        'dyl',
        'din',
        'ed',
        'gyl',
        'hil',
        'lir',
        'laran',
        'ladir',
        'lan',
        'las',
        'myr',
        'maer',
        'naer',
        'nan',
        'phyl',
        'ran',
        'vys',
        'wyn',
        'nir',
      ],
    },
    female: {
      prefix: [],
      infix: [],
      suffix: [],
    },
  },
};

export default elvish;

import { LanguagePack } from '../model';
import { distinct, notSameSubsequentComposite, notSameSubsequentVocal } from './common-rule';

const dwarvishSyllables = {
  prefix: [
    'ba',
    'bo',
    'bu',
    'br',
    'bra',
    'bro',
    'bru',
    'bl',
    'blo',
    'blu',
    'da',
    'di',
    'du',
    'dw',
    'dwa',
    'dwo',
    'dwu',
    'dr',
    'dro',
    'dru',
    'dl',
    'dli',
    'dle',
    'dlo',
    'fi',
    'fo',
    'fr',
    'fra',
    'fro',
    'fru',
    'fl',
    'fli',
    'gu',
    'gr',
    'gra',
    'gro',
    'gru',
    'gf',
    'gfi',
    'gfu',
    'gl',
    'gla',
    'glai',
    'glo',
    'gim',
    'kha',
    'kho',
    'kr',
    'kri',
    'lu',
    'pj',
    'ta',
    'tr',
    'tra',
    'tro',
    'tru',
    'tl',
    'tle',
    'tli',
    'tlo',
    'tha',
    'tho',
    'thu',
    'thr',
    'thra',
    'thro',
    'thru',
    'thran',
    'throf',
    'thrum',
    'wi',
  ],
  infix: ['a', 'i', 'o', 'u', 'aza', 'oi', 'ar', 'ng', 'ung', 'ing', 'nd', 'n', 'm', 'md', 'af', 'gor', 'ol'],
  suffix: [
    'bar',
    'bor',
    'bur',
    'br',
    'bog',
    'dur',
    'dor',
    'dil',
    'drar',
    'drur',
    'dror',
    'fil',
    'gar',
    'gor',
    'gur',
    'gim',
    'gil',
    'gi',
    'ili',
    'nir',
    'mir',
    'lur',
    'li',
    'sar',
    'sur',
    'thar',
    'thur',
    'ti',
    'tr',
  ],
};
const dwarvish: LanguagePack = {
  rules: [distinct, ...notSameSubsequentVocal, ...notSameSubsequentComposite],
  syllables: {
    male: dwarvishSyllables,
    female: dwarvishSyllables,
  },
};
export default dwarvish;
